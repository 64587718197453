<template>
  <p-dialog
    :loading="isLoading"
    :title="`${$tk('Common.General.Ticket')} #${id}`"
    @close="onClose"
    classes="left-0 md:left-auto md:w-4/5 xl:w-3/5"
  >
    <div class="flex-1 flex overflow-y-hidden" v-if="ticket">
      <div class="flex-1 md:flex overflow-y-auto md:overflow-y-hidden">
        <div
          class="p-4 md:w-72 bg-gray-100 border-r border-gray-300 overflow-y-auto"
        >
          <div class="text-sm mb-1">{{ $tk("Common.General.Status") }}</div>
          <PStatusLabel class="text-xs" :status="ticket.status" />
          <template v-if="isEmployee">
            <PSelect
              :disabled="isSending"
              :items="employees"
              :label="$tk('Common.General.Responsible')"
              class="mt-4"
              itemText="name"
              itemValue="employeeId"
              v-model="employeeId"
            />

            <PSelect
              :disabled="isSending"
              :items="priorities"
              :label="$tk('Common.General.Priority')"
              class="mt-4"
              v-model="ticket.priorityId"
            />

            <PSelect
              :disabled="isSending"
              :items="customers"
              :label="$tk('Common.General.Customer')"
              class="mt-4"
              itemText="name"
              itemValue="id"
              v-model="ticket.customerId"
            />

            <PSelect
              :disabled="isSending"
              :items="locations"
              :label="$tk('Common.General.Location')"
              class="mt-4"
              itemText="name"
              itemValue="id"
              v-model="ticket.locationId"
            />

            <PSelect
              :disabled="isSending"
              :items="users"
              :label="$tk('Common.General.ContactPerson')"
              class="mt-4"
              itemText="name"
              itemValue="id"
              v-model="orderedById"
            />

            <PSelect
              :disabled="isSending"
              :items="transactionTypes"
              :label="$tk('Common.General.TransactionType')"
              class="mt-4"
              v-model="ticket.transactionType"
            />

            <PInput
              :disabled="isSending"
              :label="$tk('Common.General.OrderNo')"
              class="mt-4"
              v-model="ticket.orderId"
            />

            <PSelect
              :disabled="isSending"
              :items="mainCategories"
              :label="$tk('Common.General.MainCategory')"
              class="mt-4"
              itemText="name"
              itemValue="id"
              v-model="ticket.mainCategoryId"
            />

            <PSelect
              :disabled="isSending"
              :items="categories"
              :label="$tk('Common.General.Category')"
              class="mt-4"
              itemText="name"
              itemValue="id"
              v-model="ticket.categoryId"
            />
          </template>

          <template v-else>
            <PText class="mt-4" :label="$tk('Common.General.Responsible')">
              <span
                v-html="
                  ticket.employee
                    ? ticket.employee.name
                    : '' || $tk('Common.General.NotSelected')
                "
              ></span>
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.Priority')">
              {{ ticket.priority }}
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.Customer')">
              {{ ticket.customerName }}
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.Location')">
              {{ ticket.locationName }}
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.ContactPerson')">
              <span
                v-html="
                  ticket.orderedBy
                    ? ticket.orderedBy.name
                    : '' || $tk('Common.General.NotSelected')
                "
              ></span>
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.TransactionType')">
              {{ ticket.transactionType }}
            </PText>

            <PText
              class="mt-4"
              :label="$tk('Common.General.OrderNo')"
              v-if="ticket.orderId"
            >
              {{ ticket.orderId }}
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.MainCategory')">
              {{ ticket.mainCategoryName || "Ikke valgt" }}
            </PText>

            <PText class="mt-4" :label="$tk('Common.General.Category')">
              <span
                v-html="
                  ticket.categoryName || $tk('Common.General.NotSelected')
                "
              ></span>
            </PText>
          </template>
        </div>
        <div class="flex-1 flex flex-col overflow-y-hidden">
          <!-- header -->
          <div class="flex-0 bg-gray-50 p-4 border-b border-gray-300">
            <h1 class="text-lg font-semibold">{{ ticket.title }}</h1>
            <div class="text-gray-500 text-sm">
              {{ ticket.createdBy.name }}
              <span v-html="$tk('TicketDialog.CreatedThisTicketAt')"></span>
              {{ ticket.createdLocal | dateAndTime }}
            </div>
          </div>

          <!-- new event -->
          <div
            v-if="canSend"
            class="flex-0 bg-gray-50 p-4 border-b border-gray-300"
          >
            <PSelect
              :disabled="isSending"
              :items="templates"
              :label="$tk('TicketDialog.DefaultAnswer')"
              class="mb-4"
              itemText="title"
              itemValue="title"
              v-if="isEmployee"
              v-model="selectedTemplateTitle"
            />

            <PTextarea v-model="description" :disabled="isSending" />

            <div
              class="mt-4 flex items-center"
              :class="{
                'justify-between': isEmployee,
                'justify-end': !isEmployee,
              }"
            >
              <PToggle
                :label="$tk('TicketDialog.InternalNote')"
                v-model="isInternal"
                v-if="isEmployee"
                :disabled="isSending"
              />

              <PFile
                :multiple="true"
                :allowPaste="true"
                :disabled="isSending"
                @change="onFileChange"
              />
            </div>
          </div>

          <!-- events -->
          <div class="shadow-inner flex-1 p-4 overflow-y-auto">
            <div
              class="p-6 border-b"
              v-for="(evt, index) in eventsSorted"
              :class="{
                'bg-orange-200 border-orange-300': evt.isInternal,
                'border-gray-200': !evt.isInternal,
              }"
              :key="index"
            >
              <h2>
                <span class="font-semibold text-sm">{{
                  evt.createdBy.name
                }}</span>
                <span class="text-gray-400 text-xs">
                  {{ evt.createdLocal | ago }}
                </span>
              </h2>
              <div class="text-sm whitespace-pre-wrap">
                {{ evt.description }}
              </div>

              <PSupportAttachments
                v-if="evt.attachmentCount > 0"
                :eventId="evt.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PDialogActions :scrollable="false">
      <PButton @click="onClose" color="secondary" :disabled="isSending">
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>

      <PTicketstatusButton
        class="ml-2"
        v-if="canSend && isEmployee"
        @click="onSend"
        :loading="isSending"
        :disabled="isLoading"
        :statusId="ticket.statusId"
      />

      <PButton
        class="ml-2"
        v-if="canSend && !isEmployee"
        @click="onSend"
        :loading="isSending"
        :disabled="isLoading"
      >
        <span v-html="$tk('Common.Actions.Send')"></span>
      </PButton>
    </PDialogActions>
  </p-dialog>
</template>

<script>
import http from "@/http";
import { mapGetters } from "vuex";
import { filter, get, reverse, sortBy } from "lodash";

import PStatusLabel from "../components/PStatusLabel.vue";
import PSupportAttachments from "../components/PSupportAttachments.vue";
import PTicketstatusButton from "../components/PTicketstatusButton.vue";

const STORE_SCOPE = "global";
const STORE_NAME = "support-templates";

const STATUS_ARCHIVED = 4;

export default {
  components: {
    PStatusLabel,
    PSupportAttachments,
    PTicketstatusButton,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      ticket: null,
      events: [],
      employees: [],
      priorities: [
        { value: 0, text: this.$tk("Common.SupportPriority.Low") },
        { value: 1, text: this.$tk("Common.SupportPriority.Normal") },
        { value: 2, text: this.$tk("Common.SupportPriority.High") },
      ],
      transactionTypes: [
        {
          value: "Order",
          text: this.$tk("Common.TransactionType.PurchaseOrder"),
        },
        {
          value: "Dispatch",
          text: this.$tk("Common.TransactionType.SalePickup"),
        },
        {
          value: "CargoShipment",
          text: this.$tk("Common.TransactionType.Delivery"),
        },
      ],
      customers: [],
      locations: [],
      users: [],
      mainCategories: [],
      categories: [],
      templates: [],
      templateJustSet: false,
      description: "",
      attachments: [],
      isInternal: false,
      isLoading: false,
      isSending: false,
      onSending: false,
      selectedTemplateIndex: 0,
      selectedTemplateTitle: "",
      employeeId: "",
    };
  },

  computed: {
    ...mapGetters(["user", "location", "customer"]),

    canSend() {
      return (
        this.ticket &&
        this.ticket.statusId !== STATUS_ARCHIVED &&
        (this.isEmployee || this.ticket.customerId === this.user.customerId)
      );
    },

    customerId() {
      return get(this.ticket, "customerId", "");
    },

    locationId() {
      return get(this.ticket, "locationId", "");
    },

    // employeeId: {
    //   get () {
    //     return get(this.ticket, "employee.id", null)
    //   },
    //   set (value) {
    //     if (this.ticket && this.ticket.employee) {
    //       this.ticket.employee.id = value
    //     }
    //   }
    // },

    orderedById: {
      get() {
        return get(this.ticket, "orderedBy.id", "");
      },
      set(value) {
        if (this.ticket && this.ticket.orderedBy) {
          this.ticket.orderedBy.id = value;
        }
      },
    },

    isEmployee() {
      return !!get(this.user, "employeeId", "");
    },

    eventsSorted() {
      return reverse(sortBy(this.events, (e) => e.createdLocal));
    },
  },

  methods: {
    async onSend(statusId) {
      this.isSending = true;

      try {
        if (this.isEmployee) {
          this.ticket = await http.post("SupportTicket", {
            id: this.ticket.id,
            title: this.ticket.title,
            description: this.ticket.description,
            priority: this.ticket.priorityId,
            mainCategoryId: this.ticket.mainCategoryId,
            categoryId: this.ticket.categoryId,
            customerId: this.ticket.customerId,
            locationId: this.ticket.locationId,
            productId: this.ticket.productId,
            orderId: this.ticket.orderId,
            transactionId: this.ticket.transactionId,
            transactionType: this.ticket.transactionType,
            isInternal: this.ticket.isInternal,
            orderedById: this.orderedById,
            employeeId: this.employeeId,
            status: statusId,
          });
        }

        const evnt = await http.post("supportevent", {
          ticketId: this.id,
          isInternal: this.isInternal,
          description: this.description,
          priority: this.ticket.priority,
          employeeId: this.employeeId,
          status: statusId,
        });

        if (this.attachments.length > 0) {
          for (const attachment of this.attachments) {
            let formData = new FormData();

            formData.append("eventId", evnt.id);
            formData.append("file", attachment);

            await http.post("SupportattAchment", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        }

        this.$emit("close");
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }

      this.isSending = false;
    },

    onFileChange(files) {
      this.attachments = files;
    },

    onClose() {
      this.$emit("close");
    },
  },

  async created() {
    this.isLoading = true;

    try {
      const [ticket, events] = await Promise.all([
        http.get("supportTicket", { params: { id: this.id } }),
        http.get("supportEvents", { params: { ticketId: this.id } }),
      ]);

      this.employeeId = get(ticket, "employee.id", "");

      this.ticket = ticket;
      this.events = events;

      if (this.isEmployee) {
        const [mainCategories, categories, customers, users, templates] =
          await Promise.all([
            http.get("supportMainCategories"),
            http.get("supportCategories"),
            http.get("customers"),
            http.get("users", {
              params: {
                customer: this.customer.id,
                include: "id,name,employeeId",
              },
            }),
            http.get("store", {
              params: {
                scope: STORE_SCOPE,
                name: STORE_NAME,
              },
            }),
          ]);

        this.mainCategories = mainCategories;
        this.categories = categories;
        this.customers = customers;
        this.employees = sortBy(
          filter(users, (u) => !!u.employeeId),
          (u) => u.name
        );
        this.templates = [
          {
            id: 0,
            title: this.$tk("TicketDialog.SelectDefaultAnswer"),
            text: "",
          },
        ].concat(get(templates, "value") || []);
        this.selectedTemplateTitle = this.$tk(
          "TicketDialog.SelectDefaultAnswer"
        );
      }
    } catch (error) {
      this.$store.dispatch("notify", { type: "negative", text: error.reason });
    }

    this.isLoading = false;
  },

  watch: {
    customerId: async function (val) {
      if (val) {
        this.locations = await http.get("locations", {
          params: { customerId: val },
        });
      } else {
        this.locations = [];
      }
    },

    locationId: async function (val) {
      const customerId = get(this.ticket, "customerId");
      if (val && customerId) {
        this.users = await http.get("users", {
          params: { customerId, locationId: val },
        });
      } else {
        this.users = [];
      }
    },

    selectedTemplateTitle: function (title) {
      if (!title) return;
      this.templateJustSet = true;
      this.description =
        this.templates.find((t) => t.title === title)?.text || "";
    },
  },
};
</script>