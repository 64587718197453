var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 flex justify-between cursor-pointer",on:{"click":_vm.onSort}},[_c('div',[_vm._t("default")],2),_c('FontAwesomeIcon',{staticClass:"ml-2",attrs:{"icon":[
        'far',
        _vm.sortField === _vm.field
          ? _vm.sortDescending
            ? 'sort-down'
            : 'sort-up'
          : 'sort'
      ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }