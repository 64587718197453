<template>
  <div class="py-4" v-if="attachments.length > 0">

    <div 
      class="text-sm mb-1" 
      v-html="`${$tk('Common.General.Attachments')}:`"
    ></div>

    <div class="flex flex-wrap -mx-4">

      <div class="px-4"
        v-for="(attachment, index) in attachments"      
        :key="index"
      >

        <PSupportAttachment
          :attachment="attachment"
        />

      </div>

    </div>

  </div>
</template>

<script>

import http from "@/http"
import PSupportAttachment from "./PSupportAttachment.vue"

export default {

  name: "p-support-attachments",

  components: {
    PSupportAttachment
  },

  props: {
    eventId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      attachments: []
    }
  },

  async created () {
    
    try {      
      
      this.attachments = await http.get("SupportAttachments", { 
        params: { 
          eventId: this.eventId 
        }
      })
    
    } catch (error) {

      this.$store.dispatch("notify", { 
        type: "negative", 
        text: error.reason 
      })

    }
  }
}
</script>