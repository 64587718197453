<template>
  <div 
    class="p-4 flex justify-between cursor-pointer" 
    @click="onSort"
  >

      <div><slot /></div>

      <FontAwesomeIcon 
        class="ml-2"
        :icon="[
          'far',
          sortField === field
            ? sortDescending
              ? 'sort-down'
              : 'sort-up'
            : 'sort'
        ]"
      />
      
    </div>
</template>

<script>
export default {
  
  name: "p-sort-header",

  props: {
    field: {
      type: String,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    sortDescending: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onSort () {
      this.$emit('sort', {
        field: this.field,
        descending: this.field === this.sortField
            ? this.sortDescending
              ? false
              : true
            : true
      })
    }
  }
}
</script>