<template>
  <div>
    <button
      @click.prevent="onClick"
      class="w-48 h-48 flex items-center justify-center p-2 bg-white border border-gray-300 hover:border-green-500"
    >
      <template v-if="isImage">
        <div class="flex flex-col items-center">
          <div class="flex-1">
            <img
              class="max-h-36"
              loading="lazy"
              :src="`${baseUrl}supportattachmentdata?id=${
                attachment.id
              }&inline=true&authtoken=${encodeURIComponent(token)}`"
            />
          </div>
          <div class="flex-0 text-xs pt-1 leading-none">
            {{ attachment.name }}
          </div>
        </div>
      </template>

      <template v-else>
        <span v-html="$tk('Common.General.File').toLowerCase()"></span>
      </template>
    </button>

    <portal to="modals">
      <div
        class="fixed inset-0 p-10 flex justify-center items-center bg-black bg-opacity-75 z-30"
        v-if="isZoomed"
      >
        <button
          class="absolute flex items-center justify-center w-10 h-10 rounded-full shadow-xl bg-gray-200 text-gray-900 top-10 right-10 z-30 hover:bg-white focus:bg-white focus:outline-none"
          @click="isZoomed = null"
        >
          <font-awesome-icon :icon="['far', 'times']" />
        </button>
        <img
          class="select-none shadow-lg"
          :src="`${baseUrl}supportattachmentdata?id=${
            attachment.id
          }&inline=true&authtoken=${encodeURIComponent(token)}`"
          alt=""
        />
      </div>
    </portal>
  </div>
</template>

<script>
import TokenService from "@/services/TokenService";
import { getConfig } from "@/config";

const config = getConfig();

export default {
  name: "p-support-attachment",

  props: {
    attachment: {
      type: Object,
      requred: true,
    },
  },
  data() {
    return {
      isZoomed: false,
    };
  },
  computed: {
    token() {
      return TokenService.getToken();
    },
    isImage() {
      return this.attachment.type.indexOf("image/") === 0;
    },
    baseUrl() {
      return config.apiUrl;
    },
  },
  methods: {
    onClick() {
      if (this.isImage) {
        this.isZoomed = true;
      } else {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          `${config.apiUrl}supportattachmentdata?id=${
            this.attachment.id
          }&inline=true&authtoken=${encodeURIComponent(this.token)}`
        );
        element.setAttribute("download", this.attachment.name);
        element.setAttribute("target", "_blank");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
  },
};
</script>