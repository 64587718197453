<template>
  <PDialog
    :title="dialogTitle"
    @close="onCancel"
    classes="left-0 md:left-auto md:w-4/5 lg:w-3/5"
  >
    <div
      class="flex-1 flex overflow-y-hidden bg-cover bg-bottom"
      :style="step === 'CATEGORY' ? `background-image: url('/img/helpdesk.jpg')` : ''
        "
    >
      <template v-if="step === 'CATEGORY'">
        <div class="flex-1 flex flex-col">
          <div class="p-4 flex-grow-0 bg-gray-50 border-b border-gray-300">
            <div
              class="text-xl"
              v-html="$tk('Common.General.Category')"
            ></div>
            <span v-html="$tk('CreateDialog.PleaseSelectCategory')"></span>
          </div>

          <div class="p-4 flex-1 overflow-y-auto">
            <PLoader v-if="isLoading" />

            <div
              v-else
              class="mb-4"
              v-for="(mainCategory, i) in mainCategories"
              :key="i"
            >
              <div class="font-semibold text-sm">{{ mainCategory.name }}</div>

              <button
                class="flex items-center w-full mt-2 p-2 border shadow rounded-full focus:outline-none text-sm"
                :class="{
                  'border-green-500 bg-green-100 text-green-700':
                    selectedCategoryId === category.id,
                  'border-gray-300 bg-white':
                    selectedCategoryId !== category.id,
                }"
                v-for="(category, j) in categoriesByParentId(mainCategory.id)"
                :key="j"
                @click="onSelect(mainCategory, category)"
              >
                <div class="ml-2">
                  <FontAwesomeIcon :icon="[
                    'far',
                    selectedCategoryId === category.id
                      ? 'check-circle'
                      : 'circle',
                  ]" />
                </div>
                <div class="ml-2">
                  {{ category.name }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="step === 'EXPLAIN'">
        <div class="flex-1 flex flex-col">
          <div class="p-4 flex-grow-0 bg-gray-50 border-b border-gray-300">
            <div
              class="text-xl"
              v-html="$tk('CreateDialog.RelatedVideo')"
            ></div>
            <span v-html="$tk('CreateDialog.RelatedVideoText')"></span>
          </div>
          <div class="p-4 flex-1 overflow-y-auto">
            <video
              width="100%"
              controls
              v-if="videoUrl"
            >
              <source
                :src="videoUrl"
                type="video/mp4"
              />
            </video>
            <div class="mt-4 w-full whitespace-pre">
              <span>{{ categoryText }}</span>
            </div>
          </div>
        </div>
      </template>

      <template v-if="step === 'DETAILS'">
        <div class="flex-1 flex flex-col md:flex-row overflow-y-auto">
          <div class="p-4 md:w-72 bg-gray-200 border-r border-gray-300">
            <PSelect
              :label="$tk('Common.General.Customer')"
              :searchable="true"
              :items="customers"
              :disabled="isCreating"
              v-model="selectedCustomerId"
              v-if="loginUser.isGlobalAdmin"
              itemText="name"
              itemValue="id"
            />

            <PText
              v-else
              class="mt-4"
              :label="$tk('Common.General.Customer')"
            >
              {{ customer.name }}
            </PText>

            <PSelect
              class="mt-4"
              :label="$tk('Common.General.Location')"
              :items="locations"
              :disabled="isCreating"
              v-model="selectedLocationId"
              v-if="loginUser.isGlobalAdmin || user.isCustomerAdmin"
              itemText="name"
              itemValue="id"
            />

            <PText
              v-else
              class="mt-4"
              :label="$tk('Common.General.Location')"
            >
              {{ location.name }}
            </PText>

            <PSelect
              class="mt-4"
              :label="$tk('Common.General.ContactPerson')"
              :items="users"
              :disabled="isCreating"
              v-model="selectedUserId"
              v-if="loginUser.isGlobalAdmin || user.isCustomerAdmin"
              itemText="name"
              itemValue="id"
            />

            <PText
              v-else
              class="mt-4"
              :label="$tk('Common.General.ContactPerson')"
            >
              {{ user.name }}
            </PText>
          </div>
          <div class="p-4 flex-1">
            <PInput
              :label="$tk('Common.General.Topic')"
              v-model="title"
              :disabled="isCreating"
            />
            <p
              class="mt-1 text-xs text-gray-500"
              v-html="$tk('CreateDialog.TopicText')"
            ></p>

            <PTextarea
              class="mt-4"
              :label="$tk('Common.General.Description')"
              v-model="description"
              :disabled="isCreating"
            />
            <p
              class="mt-1 text-xs text-gray-500"
              v-html="$tk('CreateDialog.DescriptionText')"
            ></p>

            <PProductPicker
              class="mt-4"
              :label="$tk('Common.General.Product')"
              :disabled="isCreating"
              :locationId="location.id"
              :value="selectedProductKey"
              @select="onProductSelect"
            />
            <p
              class="mt-1 text-xs text-gray-500"
              v-html="$tk('CreateDialog.ProductText')"
            ></p>

            <div
              class="mt-4 mb-1 text-sm"
              v-html="$tk('Common.General.Attachment')"
            ></div>
            <PFile
              :multiple="true"
              :allowPaste="true"
              :disabled="isCreating"
              @change="onFileChange"
            />
            <p
              class="mt-1 text-xs text-gray-500"
              v-html="$tk('CreateDialog.ImageOrPDFFile')"
            ></p>
          </div>
        </div>
      </template>
    </div>
    <PDialogActions>
      <div
        v-if="step === 'CATEGORY'"
        class="flex space-x-2"
      >
        <PButton
          class="ml-2"
          color="secondary"
          @click="$emit('close')"
        >
          <span v-html="$tk('Common.Actions.Cancel')"></span>
        </PButton>

        <PButton
          class="ml-2"
          @click="onCategoriesNext"
          :disabled="selectedCategoryId === ''"
        >
          <span v-html="$tk('Common.General.Next')"></span>
        </PButton>
      </div>

      <div
        class="ml-2 flex items-center"
        v-if="step === 'EXPLAIN'"
      >
        <div v-html="$tk('CreateDialog.VideoText')"></div>

        <PButton
          class="ml-2"
          color="secondary"
          @click="$emit('close')"
        >
          <span v-html="$tk('Common.General.YesCancel')"></span>
        </PButton>

        <PButton
          class="ml-2"
          @click="step = 'DETAILS'"
        >
          <span v-html="$tk('Common.General.NoContinue')"></span>
        </PButton>
      </div>

      <div
        v-if="step === 'DETAILS'"
        class="flex space-x-2"
      >
        <PButton
          class="ml-2"
          color="secondary"
          @click="$emit('close')"
        >
          <span v-html="$tk('Common.Actions.Cancel')"></span>
        </PButton>

        <PButton
          class="ml-2"
          @click="onCreate"
          :disabled="!canCreate"
          :loading="isCreating"
        >
          <span v-html="$tk('Common.Actions.Create')"></span>
        </PButton>
      </div>
    </PDialogActions>
  </PDialog>
</template>

<script>
import http from "@/http";

import {
  get,
  // forEach,
  find,
  filter,
  pick,
  sortBy,
} from "lodash";

import { mapGetters } from "vuex";

const key = function(o) {
  return `${o.productId}:${o.treatmentId}:${o.qualityId}`.toUpperCase();
};

export default {
  data() {
    return {
      step: "CATEGORY",
      mainCategories: [],
      categories: [],
      selectedMainCategoryId: "",
      selectedCategoryId: "",
      isLoading: false,
      isCreating: false,
      customers: [],
      locations: [],
      users: [],
      title: "",
      description: "",
      product: null,
      selectedCustomerId: "",
      selectedLocationId: "",
      selectedUserId: "",
      attachments: [],
      orderId: "",
      transactionType: "",
    };
  },

  computed: {
    ...mapGetters(["customer", "location", "user", "loginUser"]),

    canCreate() {
      return true;
    },

    videoUrl() {
      let selectedCategory = find(
        this.categories,
        (category) => category.id == this.selectedCategoryId
      );
      let videoUrl = selectedCategory ? selectedCategory.videoUrl : null;
      let host = "https://api.smartretur.no"; //this.apiEndpoint
      return videoUrl ? host + videoUrl : null;
    },

    categoryText() {
      let selectedCategory = find(
        this.categories,
        (category) => category.id == this.selectedCategoryId
      );
      return selectedCategory.faqText || "";
    },

    transactionTypeName() {
      return this.transactionType === "TO"
        ? this.$tk("Common.TransactionType.PurchaseOrder")
        : this.transactionType === "HE"
          ? this.$tk("Common.TransactionType.SalePickup")
          : this.transactionType === "GL"
            ? this.$tk("Common.TransactionType.Delivery")
            : this.transactionType === "OO"
              ? this.$tk("Common.TransactionType.Delivery")
              : `(${this.$tk("Common.General.Unknown")})`;
    },

    dialogTitle() {
      return this.orderId
        ? `${this.$tk("CreateDialog.NewTicketRegardingOrder")} ${this.orderId}`
        : this.transactionType
          ? `${this.$tk("CreateDialog.NewTicketRegardingTransactionType")} ${this.transactionTypeName
          }`
          : this.$tk("CreateDialog.NewTicket");
    },

    selectedProductKey() {
      return get(this.product, "key", "");
    },
  },

  methods: {
    isAvailable(cats) {
      console.debug("query:", this.$route.query);
      const level = this.$route.query.i ? 3 : this.$route.query.t ? 2 : 1;
      return filter(cats, (c) => {
        return (
          (c.level1 && level == 1) ||
          (c.level2 && level == 2) ||
          (c.level3 && level == 3)
        );
      });
    },

    onSelect(mainCategory, category) {
      this.selectedMainCategoryId = mainCategory.id;
      this.selectedCategoryId = category.id;
    },

    categoriesByParentId(parentId) {
      return filter(
        this.categories,
        (category) => category.parentId === parentId
      );
    },

    onProductSelect(product) {
      this.product = product;
    },

    onCategoriesNext() {
      this.step = this.videoUrl || this.categoryText ? "EXPLAIN" : "DETAILS";
    },

    async onCreate() {
      this.isCreating = true;

      try {
        const ticket = await http.post("SupportTicket", {
          mainCategoryId: this.selectedMainCategoryId,
          categoryId: this.selectedCategoryId,
          title: this.title,
          customerId: this.selectedCustomerId,
          locationId: this.selectedLocationId,
          orderedById: this.user.id,
          orderId: this.orderId,
          transactionType: this.transactionType,
          productId: get(this.product, "productId", ""),
        });

        let description = this.description;

        if (this.product) {
          description += `\n\n${this.product.qualityId}\n\n${this.product.treatmentId}`;
        }

        const evnt = await http.post("supportEvent", {
          ticketId: ticket.id,
          description: description,
        });

        if (this.attachments.length > 0) {
          for (const attachment of this.attachments) {
            let formData = new FormData();

            formData.append("eventId", evnt.id);
            formData.append("file", attachment);

            await http.post("SupportAttachment", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        }
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }

      this.isCreating = false;

      this.$emit("close");
    },

    onCancel() {
      this.$emit("close");
    },

    onFileChange(files) {
      this.attachments = files;
    },

    async init() {
      this.isLoading = true;

      try {
        const [customers, mainCategories, categories] = await Promise.all([
          http.get("customers"),
          http.get("SupportMainCategories"),
          http.get("SupportCategories"),
        ]);

        this.transactionType = this.$route.query.t;
        this.orderId = this.$route.query.i;

        this.customers = sortBy(customers, (c) => c.name);
        this.mainCategories = this.isAvailable(mainCategories);
        this.categories = this.isAvailable(categories);

        if (this.$route.query.c) {
          const category = find(
            categories,
            (c) => c.id === this.$route.query.c
          );
          if (category && category.parentId) {
            (this.selectedMainCategoryId = category.parentId),
              (this.selectedCategoryId = category.id);
            this.step = "DETAILS";
          }
        }

        const product = pick(this.$route.query, [
          "productId",
          "qualityId",
          "treatmentId",
        ]);

        if (product.productId && product.qualityId && product.treatmentId) {
          this.product = {
            ...product,
            key: key(product),
          };
        }

        this.isLoading = false;
      } catch (error) {
        this.$notifyError("", error.reason);
      }

      this.$nextTick(() => {
        this.selectedCustomerId = this.customer.id;
      });
    },
  },

  async created() {
    await this.init();
  },

  watch: {
    selectedCustomerId: async function(val) {
      if (val) {
        const promises = [
          http.get("locations", { params: { customerId: val } }),
          http.get("users", { params: { customerId: val } }),
        ];

        const [locations, users] = await Promise.all(promises);

        this.locations = sortBy(locations, (l) => l.name);
        this.users = sortBy(users, (u) => u.name);

        const currentUser = find(users, (u) => u.id === this.user.id);
        const mainUser = find(users, (l) => l.isCustomerAdmin);

        this.$nextTick(() => {
          if (this.location) {
            this.selectedLocationId = this.location.id;
          }

          if (currentUser || mainUser) {
            this.selectedUserId =
              get(currentUser, "id", "") || get(mainUser, "id", "");
          }
        });
      }
    },
  },
};
</script>