var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('transition',{attrs:{"enter-class":"transform opacity-0 scale-95","enter-active-class":"transition ease-out duration-100","enter-to-class":"transform opacity-100 scale-100","leave-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-to-class":"transform opacity-0 scale-95"}},[(_vm.isOpen)?_c('div',{staticClass:"origin-bottom-right absolute bottom-12 right-0 bg-white py-2 rounded-lg shadow-md border border-gray-300"},_vm._l((_vm.statuses),function(status,index){return _c('button',{key:index,staticClass:"flex justify-between items-center w-full py-2 px-4 space-x-4 focus:outline-none focus:bg-gray-100 hover:bg-gray-100",on:{"click":function($event){$event.preventDefault();return _vm.onSend(status)}}},[_c('div',{staticClass:"whitespace-no-wrap text-sm"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tk('PTicketstatusButton.SubmitAs'))}}),_vm._v(" "),_c('span',{staticClass:"font-semibold",domProps:{"innerHTML":_vm._s(status.name)}})]),_c('div',{staticClass:"w-4 h-4 rounded",class:{
            'bg-yellow-300': status.id === 0,
            'bg-red-500': status.id === 1,
            'bg-blue-400': status.id === 2,
            'bg-gray-500': status.id === 3,
            'bg-gray-400': status.id === 4
          }})])}),0):_vm._e()]),_c('PClickOutside',{attrs:{"do":() => _vm.isOpen = false}},[_c('PButton',{attrs:{"disabled":_vm.loading},on:{"click":_vm.onToggle}},[_c('div',{staticClass:"flex items-center"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$tk('Common.Actions.Submit'))}}),_c('div',{staticClass:"ml-3"},[(_vm.loading)?_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.isOpen ? 'chevron-down' : 'spinner'],"spin":""}}):_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.isOpen ? 'chevron-down' : 'chevron-up']}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }