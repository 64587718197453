<template>  
  <div class="relative">

    <transition
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >

      <div 
        v-if="isOpen"
        class="origin-bottom-right absolute bottom-12 right-0 bg-white py-2 rounded-lg shadow-md border border-gray-300"
      >
        <button
          class="flex justify-between items-center w-full py-2 px-4 space-x-4 focus:outline-none focus:bg-gray-100 hover:bg-gray-100"
          v-for="(status, index) in statuses"
          :key="index"
          @click.prevent="onSend(status)"
        >

          <div class="whitespace-no-wrap text-sm"><span v-html="$tk('PTicketstatusButton.SubmitAs')"></span> <span class="font-semibold" v-html="status.name"></span></div>

          <div 
            class="w-4 h-4 rounded"
            :class="{
              'bg-yellow-300': status.id === 0,
              'bg-red-500': status.id === 1,
              'bg-blue-400': status.id === 2,
              'bg-gray-500': status.id === 3,
              'bg-gray-400': status.id === 4
            }"
          >
          </div>
          
        </button>

      </div>

    </transition>

    <PClickOutside :do="() => isOpen = false">
      <PButton    
        @click="onToggle"
        :disabled="loading"
      >
        <div class="flex items-center">
          <div v-html="$tk('Common.Actions.Submit')"></div>
          <div class="ml-3">
            <FontAwesomeIcon v-if="loading" :icon="['far', isOpen ? 'chevron-down' : 'spinner']" spin />
            <FontAwesomeIcon v-else :icon="['far', isOpen ? 'chevron-down' : 'chevron-up']" />
          </div>
        </div>
      </PButton>
    </PClickOutside>
  </div>  
</template>

<script>

import { get, find } from "lodash"

export default {
  
  name: "p-ticketstatus-button",

  props: {
    statusId: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      statuses: [
        { id:  1, name: this.$tk("Common.SupportStatuses.Open") },
        { id:  2, name: this.$tk("Common.SupportStatuses.Pending") },
        { id:  3, name: this.$tk("Common.SupportStatuses.Closed") },
        { id:  4, name: this.$tk("Common.SupportStatuses.Archived") }
      ],
      isOpen: false
    }
  },


  computed: {
    statusText () {
      const status = find(this.statuses, s => s.id === this.statusId)
      return get(status, "name", "")
    }
  },


  methods: {

    onSend (status) {
      this.$emit("click", status.id)
      this.isOpen = false      
    },

    onToggle () {
      this.isOpen = !this.isOpen
    }

  }

}
</script>